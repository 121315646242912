@import "./_breakpoints.scss";

html {
  font-size: var(--fontsize-body-1);
}

body:not(.v2021) {
  * {
    box-sizing: border-box;
  }

  *:not([class*="ds-"]):not([class*="mat-"]):not([class*="leaflet-"]):not([class*="tc-privacy"]):not(
      [class*="iti_"]
    ):not([class*="startup__"]):not([class*="btn-switch"]):not([class*="span-switch"]):not([class*="footer_"]):not(
      [class*="transparent"]
    ):not([class*="log-out"]):not([class*="pagination_"]):not([class*="btn-switch"]):not([class*="span-switch"]):not(
      [class*="footer_"]
    ):not([class*="transparent"]):not([class*="log-out"]):not([class*="popup_"]):not([class*="strong-title"]):not(
      [class*="customised"]
    ):not([class*="dashboard"]):not([class*="apexcharts"]):not(foreignObject):not(th):not(td):not(
      [class*="main-footer"]
    ):not(.main-footer *) {
    background-color: var(--color-bg, transparent);
    color: var(--color-text, inherit);
  }

  h1,
  h2,
  h3,
  h4:not([class*="sidebar_"]),
  h5,
  h6,
  p {
    margin: var(--spacing-s) 0;
    line-height: 1.2;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  h1 {
    font-size: var(--fontsize-title-1);
  }

  h2 {
    font-size: var(--fontsize-title-2);
  }

  h3 {
    font-size: var(--fontsize-title-3);
  }

  h4 {
    font-size: var(--fontsize-title-4);
  }

  a.ds-btn {
    display: inline-block;
  }
}

.btn-red {
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #be0e25;
  background: #fff;
  padding: 8px 24px;
  color: #be0e25;
  font-weight: 500;
  line-height: 150%;
  font-size: 16px;

  &:hover {
    color: #fff;
    background-color: #be0e25;
  }
}

.title {
  font-family: "IBM Plex Sans Condensed";
  font-weight: 700;
  line-height: 130%;
}

.highlight {
  background-image: linear-gradient(120deg, var(--jaune) 0%, var(--jaune) 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.3em;
  background-position: 0 80%;
  padding: 0 1px;
}

.ds-btn--primary-blue {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  min-width: 8.25rem;
  border: none;
  border-radius: 1.5625rem;
  background: var(--background-dark);
  text-decoration: none;
  font-weight: 500;
  transition: all ease-in-out 0.1s;

  &,
  &:visited {
    color: var(--white);
  }

  &:hover {
    background: var(--bleu-300);
    outline: none;
  }
}

/* New form style ------- */

.input-v2 {
  border-radius: 4px;
  border: 1px solid #395179;
  background: #fff;
  padding: 10px 16px;
  color: #001d4a;
  margin-top: 8px;
  height: 32px;

  &::placeholder {
    color: var(--bleu-300);
  }

}

.input-label-v2 {
  color: #001d4a;
  font-weight: 500;
  line-height: 150%;
}

.ds-input-group--error .input-v2 {
  color: #be0e25;
  border-color: #be0e25;
  background-color: #fef8f8;
  margin-bottom: 8px;
}

.ds-input-group--error .input-label-v2 {
  color: #be0e25;
}

.ds-input-group--error .input-label-v2::before {
  position: absolute;
  margin-left: -16px;
  margin-top: 34px;
  font-family: "Material Icons Round";
  content: "\e5c8";
  text-align: center;
  font-weight: 900;
  font-size: 0.75rem;
  color: #be0e25;
}

.ds-input-group--required .input-label-v2::after {
  content: " *";
  color: #be0e25;
}

.tag {
  display: flex;
  padding: 3px 8px;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
}

.line {
  height: 30px;
  width: 1px;
  background: var(--bleu-moyen);
}

.line-horizontal {
  width: 100%;
  height: 1px;
  background-color: var(--bleu-moyen);
}

.breadcrumb {
  display: flex;
  gap: 8px;
  font-weight: 500;
  line-height: 150%;

  .breadcrumb__link {
      text-decoration: none;
      font-weight: 500;
      color: var(--white);
  }

  .highlight {
      background-image: linear-gradient(120deg, #005fca 0%, #005fca 100%);
  }

  span {
      color: var(--white);
  }

  @media screen and (max-width: $breakpoint-phone-max) {
      display: none;
  }
}

.btn-previous {
  display: none;
  gap: 8px;
  border: none;
  background: none;
  align-items: center;
  padding: 8px 0px;
  margin-bottom: 8px;

  p {
      text-decoration: underline;
      font-weight: 500;
      font-size: 16px;
      color: var(--white);
      margin: 0;
  }

  @media screen and (max-width: $breakpoint-phone-max) {
      display: flex;
      align-items: flex-start;
  }

  dep-icon {
      color: var(--white);
      font-size: 24px;

      svg {
        fill: var(--white);
      }
  }
}